export const defaults = {
    page_title: "Тина Канделаки - Телеграм.SITES",
    main_url: "https://tracker.tk-telegram.com/tracking202/redirect/go.php?acip=615",
    prosper_tracker_suffix: "?acip=615",
    name: "Тина Канделаки",
    tag: "@tikandelaki",
    main_avatar_url: "/img/tk.jpg",
    parsers:[
      {
        name: "Тина Канделаки",
        parser: "TINA",
        tag: "@tikandelaki",
        main_url: "https://tracker.tk-telegram.com/tracking202/redirect/go.php?acip=615",
        avatar_url: "/img/tk.jpg",
        contacts_phrase: "Представляешь? Посмотри мой канал в телеге...",
        new_messages: 3
      }
    ],
    posts: []
  }
