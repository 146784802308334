import {useEffect, useRef} from "react";
import PostItem from "./widgets/PostItem";
import PostItemSubscribe from "./widgets/PostItemSubscribe";
import {useSelector} from "react-redux";

function Posts() {

    const bottomRef = useRef(null);

    const global_reducer = useSelector(state => state.global)

    function clickToMain(e){
        window.open(global_reducer.main_url)
    }

    useEffect(() => {
        const interval = setInterval(()=>{
            document.querySelectorAll("iframe").forEach(el => {
                el.style.colorScheme = "light"
            })
            console.log(123)
        },10)

        setTimeout(()=>{
            bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            clearInterval(interval)
        },2000)


    }, []);

    useEffect(() => {

    }, [global_reducer]);

    return (
        <>
            <div className="container active" id="chatBox">
                <div className="content-header" onClick={clickToMain}>
                    <div className="image" style={{cursor: "pointer"}}>
                        <img src={global_reducer.main_avatar_url} alt="" />
                    </div>
                    <div className="details">
                        <h3>{global_reducer.name}</h3>
                        <span>онлайн</span>
                    </div>
                    <div className="icons">
                        <i className="fas fa-phone-alt"></i>
                        <i className="fas fa-ellipsis-v"></i>
                    </div>
                </div>
                <div className="chat-container">
                    <div className={"chat-container-scroller"} id={"widget-box"}>
                        {
                            global_reducer.posts !== [] ?
                                global_reducer.posts.map(
                                    (post) => {
                                        return <PostItem url={post.link} key={post.link}/>
                                    }
                                ) : <></>
                        }

                        <span id={"post_end"} ref={bottomRef}></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Posts